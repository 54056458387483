<script>
import UsersApi from '@/api/users'
import UsersForm from './UsersForm'

export default {
  extends: UsersForm,

  mixins: [UsersApi],

  methods: {
    handleFormSubmit() {
      if (this.$v.$invalid) {
        this.$refs.form.validate()
        window.snackbar('Ошибки в форме', 'error')
        return
      }

      this.isSubmit = true

      this.createUser(this.form)
        .then(() => {
          window.snackbar('Пользователь успешно добавлен', 'info')
          this.$router.push({ name: 'users' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>